<script lang="ts">
    import { FeedbackEaseOfUse } from "../../model/Feedback/FeedbackEaseOfUse";
    import { t } from 'svelte-i18n';  // Import the translation function

    export let easeOfUse: FeedbackEaseOfUse;

    function selectEaseOfUse(value: FeedbackEaseOfUse) {
        easeOfUse = value;
    }
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t('feedback.steps.easeOfUse')}</h3>
    <div class="grid grid-cols-3 gap-4">
        <button
                class:btn-selected={easeOfUse === FeedbackEaseOfUse.YES}
                class="btn btn-outline"
                on:click={() => selectEaseOfUse(FeedbackEaseOfUse.YES)}
        >
            {$t('feedback.easeOfUse.yes')}
        </button>
        <button
                class:btn-selected={easeOfUse === FeedbackEaseOfUse.SOMEWHAT}
                class="btn btn-outline"
                on:click={() => selectEaseOfUse(FeedbackEaseOfUse.SOMEWHAT)}
        >
            {$t('feedback.easeOfUse.somewhat')}
        </button>
        <button
                class:btn-selected={easeOfUse === FeedbackEaseOfUse.NO}
                class="btn btn-outline"
                on:click={() => selectEaseOfUse(FeedbackEaseOfUse.NO)}
        >
            {$t('feedback.easeOfUse.no')}
        </button>
    </div>
</div>

<style>
    .btn-outline {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        background-color: transparent;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.2s ease;
    }

    .btn-selected {
        background-color: #28a745;
        color: white;
    }
</style>
