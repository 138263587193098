<script lang="ts">
    import MoodSelection from "./MoodSelectionStep.svelte";
    import RatingFeedbackStep from "./RatingFeedbackStep.svelte";
    import ClarityFeedbackStep from "./ClarityFeedbackStep.svelte";
    import EaseOfUseFeedbackStep from "./EaseOfUseFeedbackStep.svelte";
    import AIComfortFeedbackStep from "./AIComfortFeedbackStep.svelte";
    import OptionalFeedbackStep from "./OptionalFeedbackStep.svelte";
    import RecommendationAndSuggestionsFeedbackStep from "./RecommendationAndSuggestionsFeedbackStep.svelte";
    import { onMount, onDestroy } from 'svelte';
    import {submitFeedback} from "../../service/FeedbackService";
    import {FeedbackMood} from "../../model/Feedback/FeedbackMood";
    import type {Feedback} from "../../model/Feedback";
    import {FeedbackRating} from "../../model/Feedback/FeedbackRating";
    import {FeedbackClarity} from "../../model/Feedback/FeedbackClarity";
    import {FeedbackEaseOfUse} from "../../model/Feedback/FeedbackEaseOfUse";
    import type {FeedbackComfort} from "../../model/Feedback/FeedbackComfort";
    import {FeedbackPreference} from "../../model/Feedback/FeedbackPreference";
    import {FeedbackRecommendation} from "../../model/Feedback/FeedbackRecommendation";
    import { t } from 'svelte-i18n';

    let currentStep = 1;
    let feedbackMood: FeedbackMood | undefined;
    let rating: FeedbackRating | undefined;
    let clarity: FeedbackClarity | undefined;
    let easeOfUse:  FeedbackEaseOfUse | undefined;
    let comfort: FeedbackComfort | undefined;
    let preference: FeedbackPreference | undefined;
    let recommendation: FeedbackRecommendation | undefined;
    let missingFeedback = '';
    let improvementSuggestions = '';

    let alert = ""
    let isError = false

    // This function validates the current step
    $: isValidStep = (() => {
        switch (currentStep) {
            case 1: return feedbackMood !== undefined;
            case 2: return rating !== undefined;
            case 3: return clarity !== undefined;
            case 4: return easeOfUse !== undefined;
            case 5: return comfort !== undefined && preference !== undefined;
            case 6: return true;
            case 7: return recommendation !== undefined;
            default: return false;
        }
    })();

    function nextStep() {
        if (currentStep < 7) currentStep++;
        else handleSubmit();
    }

    function previousStep() {
        if (currentStep > 1) currentStep--;
    }

    async function handleSubmit() {
        if (currentStep<2) return
        const feedback: Feedback = {
            interviewId: "-2817866329954337712",
            feedbackMood: feedbackMood,
            rating,
            clarity,
            easeOfUse,
            comfort,
            preference,
            recommendation,
            missingFeedback,
            improvementSuggestions
        };

        currentStep++
        submitFeedback(feedback)
            .then(() => { alert = $t('feedback.success'); })
            .catch(() => { alert = $t('feedback.error'); isError = true; });
    }

    // Detect page exit (user navigating away, closing, or refreshing) and submit automatically
    onMount(() => {
        const handleBeforeUnload = () => {
            handleSubmit();  // Automatically submit feedback without showing a prompt
        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
    });

    onDestroy(() => {
        handleSubmit()
    });
</script>

<div class="p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
    <h2 class="text-xl font-semibold mb-4">{$t('feedback.title')}</h2>
    <p class="text-sm text-gray-600 mb-6">
        {$t('feedback.description')}
        <br>
        <b>{$t('feedback.disclaimer')}</b>
    </p>

    {#if currentStep === 1}
        <MoodSelection bind:feedbackMood/>
    {/if}
    {#if currentStep === 2}
        <RatingFeedbackStep bind:rating />
    {/if}
    {#if currentStep === 3}
        <ClarityFeedbackStep bind:clarity />
    {/if}
    {#if currentStep === 4}
        <EaseOfUseFeedbackStep bind:easeOfUse />
    {/if}
    {#if currentStep === 5}
        <AIComfortFeedbackStep bind:comfort bind:preference  />
    {/if}
    {#if currentStep === 6}
        <OptionalFeedbackStep bind:missingFeedback  />
    {/if}
    {#if currentStep === 7}
        <RecommendationAndSuggestionsFeedbackStep bind:recommendation bind:improvementSuggestions />
    {/if}
    {#if currentStep === 8}
        {#if alert!==""}
            {#if !isError}
                <div role="alert" class="alert alert-success">
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                        <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{alert}</span>
                </div>
            {:else}
                <div role="alert" class="alert alert-error">
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                        <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{alert}</span>
                </div>
            {/if}
        {/if}
    {/if}

    <div class="flex justify-between mt-4">
        {#if currentStep > 1}
            <button class="btn bg-gray-400 text-white" on:click={previousStep}>Back</button>
        {/if}
        <button
                class="btn bg-green-500 text-white ml-auto"
                on:click={nextStep}
                disabled={!isValidStep}
        >
            {#if currentStep === 7}
                {$t('feedback.steps.submit')}
            {:else}
                {$t('feedback.steps.next')}
            {/if}
        </button>
    </div>
</div>

<style>
    .btn[disabled] {
        background-color: #c1c1c1;
        cursor: not-allowed;
    }
</style>