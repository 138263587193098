// src/i18n.ts
import { register, init, getLocaleFromNavigator, locale } from 'svelte-i18n';
import {writable} from "svelte/store";

export const i18nLanguageStore = writable<i18nLanguage>()

export enum i18nLanguage{
    ENGLISH ='en', ESPAÑOL='es'
}

register('en', () => import('./i18n/en.json'));
register('es', () => import('./i18n/es.json'));

export const setupI18n = () => {
    const savedLocale = localStorage.getItem('locale') || getLocaleFromNavigator();
    return init({
        fallbackLocale: i18nLanguage.ENGLISH,
        initialLocale: savedLocale,
    });
};

export function getUserLocale(): i18nLanguage {
    const userLocale = navigator.language.split('-')[0];
    if (Object.values(i18nLanguage).includes(userLocale as i18nLanguage)) {
        return userLocale as i18nLanguage;
    } else {
        return i18nLanguage.ENGLISH;
    }
}

i18nLanguageStore.subscribe((newLocale) => {
    if (newLocale !== undefined) {
        // Check if newLocale is an enum key and get its value
        const localeValue = i18nLanguage[newLocale as keyof typeof i18nLanguage];

        // Set the locale value
        locale.set(localeValue);
        localStorage.setItem('locale', localeValue);
    }
});