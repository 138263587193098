<script lang="ts">
    import { FeedbackRating } from "../../model/Feedback/FeedbackRating.js";
    import { t } from "svelte-i18n"; // Import the i18n store

    export let rating: FeedbackRating;

    function selectRating(value: FeedbackRating) {
        rating = value;
    }
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t("feedback.steps.rating")}</h3>
    <div class="grid grid-cols-2 gap-4">
        <button
                class:btn-selected={rating === FeedbackRating.EXCELLENT}
                class="btn btn-outline"
                on:click={() => selectRating(FeedbackRating.EXCELLENT)}
        >
            {$t("feedback.rating.excellent")}
        </button>
        <button
                class:btn-selected={rating === FeedbackRating.GOOD}
                class="btn btn-outline"
                on:click={() => selectRating(FeedbackRating.GOOD)}
        >
            {$t("feedback.rating.good")}
        </button>
        <button
                class:btn-selected={rating === FeedbackRating.NEUTRAL}
                class="btn btn-outline"
                on:click={() => selectRating(FeedbackRating.NEUTRAL)}
        >
            {$t("feedback.rating.neutral")}
        </button>
        <button
                class:btn-selected={rating === FeedbackRating.BAD}
                class="btn btn-outline"
                on:click={() => selectRating(FeedbackRating.BAD)}
        >
            {$t("feedback.rating.bad")}
        </button>
        <button
                class:btn-selected={rating === FeedbackRating.TERRIBLE}
                class="btn btn-outline"
                on:click={() => selectRating(FeedbackRating.TERRIBLE)}
        >
            {$t("feedback.rating.terrible")}
        </button>
    </div>
</div>

<style>
    .btn-outline {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        background-color: transparent;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.2s ease;
    }

    .btn-selected {
        background-color: #28a745;
        color: white;
    }
</style>
