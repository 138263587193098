import {makeRequest, Method} from "./Requests";
import type {ErrorResponse} from "./dto/ErrorResponse";
import type {Interview} from "../model/Interview";

export const getInterview = async (interviewCode: string) => {


    const response = await fetch(import.meta.env.VITE_GET_INTERVIEW_CLIENT + interviewCode, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
        },

    });


    if (!response.ok) {
            const errorResponse = await response.json();
            console.error(`Error: ${errorResponse.message} (Code: ${errorResponse.code})`);
            throw Error(errorResponse.code)
    }

    return await response.json() as Interview;
}