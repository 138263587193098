<script lang="ts">
    import {FeedbackMood} from "../../model/Feedback/FeedbackMood";
    import { t } from 'svelte-i18n';
    export let feedbackMood: FeedbackMood;
    const moods = [
        { emoji: '😞', value: FeedbackMood.BAD },
        { emoji: '😐', value: FeedbackMood.MID },
        { emoji: '😊', value: FeedbackMood.GOOD }
    ];

    function selectMood(value: FeedbackMood) {
        feedbackMood = value;
    }
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t("feedback.steps.mood")}</h3>
    <div class="flex justify-around items-center mb-6">
        {#each moods as mood}
            <button
                    class="flex flex-col items-center space-y-1"
                    on:click={() => selectMood(mood.value)}
                    class:selected={feedbackMood === mood.value}
            >
                <span class="text-4xl">{mood.emoji}</span>
            </button>
        {/each}
    </div>
</div>

<style>
    .selected {
        background-color: #4CAF50;
        border-radius: 50%;
    }
</style>
