<script lang="ts">
    import {
        currentContent,
        currentPage,
        isUsingSupportedBrowser
    } from "./redux/stores";
    import Landing from "./lib/Landing.svelte";
    import PreInterviewContent from "./lib/PreInterviewContent.svelte";
    import {onMount} from "svelte";
    import {onClickJoin} from "./service/JoinService";
    import * as Sentry from "@sentry/svelte";
    import {setupI18n} from "./i18n";
    import ApplicantLeftMeeting from "./lib/interview/ApplicantLeftMeeting.svelte";

    onMount(async () => {
        if (!checkBrowser()){
            $currentContent = Landing
            $currentPage = PreInterviewContent
            return
        }
        // Get the path from the URL (e.g., "/some-page")
        let code = window.location.pathname.substring(1).toUpperCase()
       if (code !== "" && code.includes("ASTRO")) {
            await onClickJoin(code)
        }else{
            $currentContent = Landing
            $currentPage = PreInterviewContent
        }
    });

   function checkBrowser(){
       const userAgent = window.navigator.userAgent;
       const isChrome = userAgent.includes('Chrome') || userAgent.includes('CriOS');
       const isEdge = userAgent.includes('Edg'); // Edge Chromium includes "Edg", older Edge versions include "Edge"

       if (!isChrome && !isEdge) {
           $isUsingSupportedBrowser = false
           return false
       }
       return true
   }

    if (import.meta.env.PROD) {
        Sentry.init({
            dsn: "https://809ddc325ab113ece28701ac9e3300b0@o4507903424200704.ingest.us.sentry.io/4507919120728064",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost",  new RegExp(`^${import.meta.env.VITE_BASE_URL}/api`)],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }



    let i18nReady = false;

    onMount(async () => {
        await setupI18n();
        i18nReady = true;
    });
</script>

{#if !$isUsingSupportedBrowser}
    <div class="alert alert-error shadow-lg">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>For the optimal interview experience, please use Chrome or Edge.</span>
        </div>
    </div>
{/if}

{#if i18nReady}
    <svelte:component this={$currentPage}/>
{:else}
    <p>Loading...</p>
{/if}

