<script lang="ts">
    import { FeedbackClarity } from "../../model/Feedback/FeedbackClarity";
    import { t } from 'svelte-i18n'; // Import the translation function

    export let clarity: FeedbackClarity;

    function selectClarity(value: FeedbackClarity) {
        clarity = value;
    }
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t('feedback.steps.clarity')}</h3>
    <div class="grid grid-cols-3 gap-4">
        <button
                class:btn-selected={clarity === FeedbackClarity.YES}
                class="btn btn-outline"
                on:click={() => selectClarity(FeedbackClarity.YES)}
        >
            {$t('feedback.clarity.yes')}
        </button>
        <button
                class:btn-selected={clarity === FeedbackClarity.SOMEWHAT}
                class="btn btn-outline"
                on:click={() => selectClarity(FeedbackClarity.SOMEWHAT)}
        >
            {$t('feedback.clarity.somewhat')}
        </button>
        <button
                class:btn-selected={clarity === FeedbackClarity.NO}
                class="btn btn-outline"
                on:click={() => selectClarity(FeedbackClarity.NO)}
        >
            {$t('feedback.clarity.no')}
        </button>
    </div>
</div>

<style>
    .btn-outline {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        background-color: transparent;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.2s ease;
    }

    .btn-selected {
        background-color: #28a745;
        color: white;
    }
</style>
