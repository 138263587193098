<script lang="ts">
    import { t } from 'svelte-i18n';
    import {

        errorMessage,
        errorWhileLookingForCode,

        isUsingSupportedBrowser
    } from "../redux/stores";
    import { onDestroy, onMount } from "svelte";
    import { onClickJoin } from "../service/JoinService";

    let interviewCode: string = "ASTRO-";

    let currentImageIndex = 0;
    let images = [
        '/web_demo_1.webp',
        '/web_demo_3.webp',
    ];

    function changeImage() {
        currentImageIndex = (currentImageIndex + 1) % images.length;
    }

    let intervalId: number;

    onMount(() => {
        intervalId = setInterval(changeImage, 2000);  // changes image every 2 seconds
    });

    onDestroy(() => {
        clearInterval(intervalId);
    });
</script>

<section class="text-gray-600 body-font mt-20 p-10">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
        <div class="md:w-1/2 md:pr-16 flex flex-col md:items-start text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font text-4xl mb-8 font-medium text-gray-900">
                {$t("start_interview_journey")}
            </h1>
            <p class="mb-8 leading-relaxed text-lg">
                {$t("enter_unique_code")}
            </p>
            <div class="flex items-center w-full border border-gray-300 rounded-lg">
                {#if !$isUsingSupportedBrowser}
                    <div class="alert alert-error">
                        <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                             viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <span>{$t("unsupported_browser_warning")}</span>
                    </div>
                {:else}
                    <input type="text" id="join_meeting_input" name="meeting_code" class="flex-grow py-2 px-4 text-lg"
                           placeholder="{$t('enter_interview_code_placeholder')}" bind:value={interviewCode}>
                    <button type="button" class="bg-blue-600 text-white py-2 px-6 rounded-r-lg"
                            on:click={() => onClickJoin(interviewCode)}>
                        {$t("join")}
                    </button>
                {/if}
            </div>

            {#if $errorWhileLookingForCode}
                <div class="alert alert-error">
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none"
                         viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <span>{$t("error_message", { error_message: $errorMessage })}</span>
                </div>
            {/if}
        </div>

        <div class="md:w-1/2 w-full">
            <div class="mockup-browser border bg-base-300">
                <div class="mockup-browser-toolbar">
                    <div class="input">https://interview.astrovel.net</div>
                </div>
                <img src={images[currentImageIndex]} alt="first image" id="first-image"
                     class="object-cover object-center rounded">
            </div>
        </div>
    </div>
</section>
