import { writable } from 'svelte/store';
import Landing from "../lib/Landing.svelte";
import type {Interview} from "../model/Interview";

export const isLoading = writable(false)

export const isUsingSupportedBrowser = writable(true)

export const errorMessage = writable<string>("Invalid meeting code")

export const errorWhileLookingForCode = writable(false)

export const currentContent = writable();

export const interviewData = writable<Interview>();

export const currentPage  = writable();

export const username = writable("User")

export const isRecordingInterview = writable(false)


export const isUserTalking  = writable(false);


export const isAudioPlaying  = writable(false);

export let INTERVIEW_ID = "-1"


