// src/lib/Chronometer.ts
export class Chronometer {
    private static instance: Chronometer;
    private startTime: number = 0;
    private elapsedTime: number = 0;
    private intervalId: number | null = null;
    private running: boolean = false;

    private constructor() {}

    // Singleton access method
    public static getInstance(): Chronometer {
        if (!Chronometer.instance) {
            Chronometer.instance = new Chronometer();
        }
        return Chronometer.instance;
    }

    public getStartTime(){
        return this.startTime;
    }

    public getElapsedTime(){
        return this.elapsedTime;
    }

    // Start the chronometer
    public start(): void {
        if (this.running) return;

        this.running = true;
        this.startTime = Date.now() - this.elapsedTime;

        this.intervalId = window.setInterval(() => {
            this.elapsedTime = Date.now() - this.startTime;
        }, 100); // Update every 100ms
    }

    // Stop the chronometer
    public stop(): void {
        if (!this.running) return;

        this.running = false;
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    // Reset the chronometer
    public reset(): void {
        this.stop();
        this.elapsedTime = 0;
    }

    // Get the elapsed time
    public getTime(): number {
        return this.elapsedTime;
    }

    // Check if chronometer is running
    public isRunning(): boolean {
        return this.running;
    }
}