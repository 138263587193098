<script lang="ts">
    import { t } from 'svelte-i18n';
    import { writable, derived } from 'svelte/store';
    import { onMount } from 'svelte';

    interface PermissionState {
        camera: PermissionStatus['state'] | 'unsupported';
        microphone: PermissionStatus['state'] | 'unsupported';
    }

    const permissions = writable<PermissionState>({ camera: 'unsupported', microphone: 'unsupported' });

    const showModal = derived(permissions, $permissions =>
        $permissions.camera === 'denied' || $permissions.microphone === 'denied' ||
        $permissions.camera === 'prompt' || $permissions.microphone === 'prompt'
    );

    async function queryPermissions() {
        try {
            const cameraPermission = await navigator.permissions.query({ name: 'camera' as PermissionName });
            const microphonePermission = await navigator.permissions.query({ name: 'microphone' as PermissionName });

            permissions.set({
                camera: cameraPermission.state,
                microphone: microphonePermission.state,
            });
        } catch (error) {
            console.error('Error querying permissions:', error);
        }
    }

    async function requestPermissions() {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            await queryPermissions();
        } catch (error) {
            console.error('Error requesting permissions:', error);
        }
    }

    onMount(() => {
        queryPermissions();
    });
</script>

{#if $showModal}
    <div class="modal modal-open">
        <div class="modal-box">
            <h3 class="font-bold text-lg">{$t("permissions_required")}</h3>
            <p class="py-4">{$t("grant_access_camera_microphone")}</p>
            <div class="modal-action">
                <button class="btn" on:click={requestPermissions}>{$t("retry")}</button>
            </div>
        </div>
    </div>
{/if}