<script lang="ts">
    import Landing from "./Landing.svelte";
    import { currentContent, isLoading } from "../redux/stores";
</script>

<body class="bg-gray-50">
<!-- header section -->
<div class="navbar bg-base-100" on:click={() => { $currentContent = Landing }}>
    <a class="btn btn-ghost normal-case flex items-center space-x-2">
        <!-- Logo -->
        <img src="/astro.svg" alt="Astrovel Logo" class="logo">
        <!-- Text -->
        <span class="astrovel-text">Astrovel</span>
    </a>
</div>

{#if $isLoading}
    <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-800 z-50">
        <span class="loading loading-spinner loading-lg custom-spinner-size"></span>
    </div>
{:else}
    <svelte:component this={$currentContent}/>
{/if}
</body>

<style>
    /* Ensure to include your font-face definition here */
    @font-face {
        font-family: 'HelveticaNeueLTStd-Bd';
        src: url('/public/HelveticaNeueLTStd-Bd.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
    }

    .logo {
        width: 40px;  /* Adjust the size of the logo */
        height: auto; /* Maintain aspect ratio */
    }

    .astrovel-text {
        font-family: 'HelveticaNeueLTStd-Bd', sans-serif;
        font-size: 24px; /* Adjust font size relative to the logo */
        color: black;    /* You can set the text color here */
        margin-left: 10px; /* Space between the logo and text */
    }

    .custom-spinner-size {
        width: 250px;
        height: 250px;
        border-width: 8px;
    }
</style>
