<script>
    import { t } from 'svelte-i18n'
    export let missingFeedback = '';
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t('feedback.steps.missing')}</h3>
    <textarea
            class="textarea textarea-bordered w-full mb-4"
            bind:value={missingFeedback}
            placeholder="{$t('feedback.missing.placeholder')}"
    ></textarea>
</div>

<style>
    .textarea {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-weight: 500;
    }
</style>
