<script lang="ts">
    import { t } from 'svelte-i18n';
    import FeedbackForm from "../Feedback/FeedbackForm.svelte";
</script>

<div class="flex flex-col items-center justify-center h-screen bg-gray-100">
    <div class="text-center">
        <h1 class="text-4xl font-bold mb-4">{$t("you_left_meeting")}</h1>
    </div>
    <div class="flex items-center justify-center">
        <div class="card bg-base-100 shadow-xl">
            <div class="card-body items-center text-center">
                <div class="card-title">
                    <span class="iconify text-6xl" data-icon="mdi-lock" data-inline="false"></span>
                </div>
                <FeedbackForm/>
            </div>
        </div>
    </div>
</div>
