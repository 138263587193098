<script lang="ts">
    import { FeedbackComfort } from "../../model/Feedback/FeedbackComfort";
    import { FeedbackPreference } from "../../model/Feedback/FeedbackPreference";
    import { t } from 'svelte-i18n';  // Import the translation function

    export let comfort: FeedbackComfort;
    export let preference: FeedbackPreference;

    function selectComfort(value: FeedbackComfort) {
        comfort = value;
    }

    function selectPreference(value: FeedbackPreference) {
        preference = value;
    }
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t('feedback.steps.comfort')}</h3>
    <div class="grid grid-cols-3 gap-4">
        <button
                class:btn-selected={comfort === FeedbackComfort.YES}
                class="btn btn-outline"
                on:click={() => selectComfort(FeedbackComfort.YES)}
        >
            {$t('feedback.comfort.yes')}
        </button>
        <button
                class:btn-selected={comfort === FeedbackComfort.SOMEWHAT}
                class="btn btn-outline"
                on:click={() => selectComfort(FeedbackComfort.SOMEWHAT)}
        >
            {$t('feedback.comfort.somewhat')}
        </button>
        <button
                class:btn-selected={comfort === FeedbackComfort.NO}
                class="btn btn-outline"
                on:click={() => selectComfort(FeedbackComfort.NO)}
        >
            {$t('feedback.comfort.no')}
        </button>
    </div>

    <h3 class="text-lg font-semibold mt-4 mb-4">{$t('feedback.steps.preference')}</h3>
    <div class="grid grid-cols-3 gap-4">
        <button
                class:btn-selected={preference === FeedbackPreference.AI}
                class="btn btn-outline"
                on:click={() => selectPreference(FeedbackPreference.AI)}
        >
            {$t('feedback.preference.ai')}
        </button>
        <button
                class:btn-selected={preference === FeedbackPreference.REAL_PERSON}
                class="btn btn-outline"
                on:click={() => selectPreference(FeedbackPreference.REAL_PERSON)}
        >
            {$t('feedback.preference.realPerson')}
        </button>
        <button
                class:btn-selected={preference === FeedbackPreference.DOESNT_MATTER}
                class="btn btn-outline"
                on:click={() => selectPreference(FeedbackPreference.DOESNT_MATTER)}
        >
            {$t('feedback.preference.doesntMatter')}
        </button>
    </div>
</div>

<style>
    .btn-outline {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        background-color: transparent;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.2s ease;
    }

    .btn-selected {
        background-color: #28a745;
        color: white;
    }
</style>
