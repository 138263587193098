<script lang="ts">
    import { FeedbackRecommendation } from "../../model/Feedback/FeedbackRecommendation";
    import { t } from 'svelte-i18n';  // Import the translation function

    export let recommendation: FeedbackRecommendation;
    export let improvementSuggestions: string = '';

    function selectRecommendation(value: FeedbackRecommendation) {
        recommendation = value;
    }
</script>

<div>
    <h3 class="text-lg font-semibold mb-4">{$t('feedback.steps.recommendation')}</h3>
    <div class="grid grid-cols-2 gap-4">
        <button
                class:btn-selected={recommendation === FeedbackRecommendation.VERY_LIKELY}
                class="btn btn-outline"
                on:click={() => selectRecommendation(FeedbackRecommendation.VERY_LIKELY)}
        >
            {$t('feedback.recommendation.veryLikely')}
        </button>
        <button
                class:btn-selected={recommendation === FeedbackRecommendation.SOMEWHAT_LIKELY}
                class="btn btn-outline"
                on:click={() => selectRecommendation(FeedbackRecommendation.SOMEWHAT_LIKELY)}
        >
            {$t('feedback.recommendation.somewhatLikely')}
        </button>
        <button
                class:btn-selected={recommendation === FeedbackRecommendation.NEUTRAL}
                class="btn btn-outline"
                on:click={() => selectRecommendation(FeedbackRecommendation.NEUTRAL)}
        >
            {$t('feedback.recommendation.neutral')}
        </button>
        <button
                class:btn-selected={recommendation === FeedbackRecommendation.NOT_LIKELY}
                class="btn btn-outline"
                on:click={() => selectRecommendation(FeedbackRecommendation.NOT_LIKELY)}
        >
            {$t('feedback.recommendation.notLikely')}
        </button>
        <button
                class:btn-selected={recommendation === FeedbackRecommendation.NEVER}
                class="btn btn-outline"
                on:click={() => selectRecommendation(FeedbackRecommendation.NEVER)}
        >
            {$t('feedback.recommendation.never')}
        </button>
    </div>

    <h3 class="text-lg font-semibold mt-4 mb-4">{$t('feedback.steps.improvement')}</h3>
    <textarea
            class="textarea textarea-bordered w-full mb-4"
            bind:value={improvementSuggestions}
            placeholder="{$t('feedback.improvement.placeholder')}"
    ></textarea>
</div>

<style>
    .btn-outline {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        background-color: transparent;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.2s ease;
    }

    .btn-selected {
        background-color: #28a745;
        color: white;
    }

    .textarea {
        border: 1px solid #ccc;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-weight: 500;
    }
</style>
