export function startVideoStream() {
    // Constraints define what media we want to access; in this case, we're accessing the video
    const constraints = {
        video: true
    };

    // Use the navigator.mediaDevices.getUserMedia function to access the webcam
    navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            // Get the video element
            const video = document.getElementById('webcam');
            if (!video) {
                console.error("Video element not found!");
                return;
            }

            // Set the source of the video element to the webcam stream
            video.srcObject = stream;
        })
        .catch((error) => {
            console.error("Error accessing the camera:", error);
        });
}

export function stopVideoStream() {
    // Get the video element
    const video = document.getElementById('webcam');
    if (!video) {
        console.error("Video element not found!");
        return;
    }

    // Check if the video has a stream attached to it
    const stream = video.srcObject;
    if (stream) {
        // Get all tracks from the stream and stop them
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());

        // Clear the video source
        video.srcObject = null;
    }
}
