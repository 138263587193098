import type {Feedback} from "../model/Feedback";

export async function submitFeedback(feedback: Feedback){

    return await fetch(import.meta.env.VITE_SUBMIT_FEEDBACK, {
        method: 'POST',
        headers: {
            'content-type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(feedback),
    })
}