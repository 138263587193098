c<script lang="ts">
    import { t } from 'svelte-i18n';
    import { currentPage, interviewData } from "../redux/stores.js";
    import RequestCameraAndMic from "../RequestCameraAndMic.svelte";
    import SendAudioTest from "./interview/SendAudioTest.svelte";

    let videoDevices = [];
    let audioDevices = [];
    let selectedVideoDevice = '';
    let selectedAudioDevice = '';
    let stream;

    async function getDevices() {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            const devices = await navigator.mediaDevices.enumerateDevices();
            videoDevices = devices.filter(device => device.kind === 'videoinput');
            audioDevices = devices.filter(device => device.kind === 'audioinput');

            // Set default selected devices
            if (videoDevices.length > 0) {
                selectedVideoDevice = videoDevices[0].deviceId;
            }
            if (audioDevices.length > 0) {
                selectedAudioDevice = audioDevices[0].deviceId;
            }
        } catch (err) {
            console.error('Error getting media devices.', err);
        }
    }

    async function updateStream() {
        try {
            const constraints = {
                video: selectedVideoDevice ? { deviceId: { exact: selectedVideoDevice } } : true,
                audio: selectedAudioDevice ? { deviceId: { exact: selectedAudioDevice } } : true
            };
            const newStream = await navigator.mediaDevices.getUserMedia(constraints);
            const videoElement = document.getElementById('preview');

            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }

            stream = newStream;
            videoElement.srcObject = stream;
        } catch (err) {
            console.error('Error updating media devices.', err);
        }
    }

    function stopVideo() {
        const videoElement = document.getElementById('preview');
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            stream = null;
            videoElement.srcObject = null;
        }
    }

    getDevices().then(updateStream);

    const joinInterview = () => {
        stopVideo();
        $currentPage = SendAudioTest;
    };
</script>

<RequestCameraAndMic/>
<section class="text-gray-600 body-font mt-20 p-10">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
        <div class="md:w-1/2 md:pr-16 flex flex-col md:items-start text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font text-4xl mb-8 font-medium text-gray-900">
                {$t("ready_to_join_meeting").replace("{name}", $interviewData.applicant.name)}
            </h1>
            <p class="mb-8 leading-relaxed text-lg">
                {$t("ensure_camera_mic")}
            </p>
            <button type="button" class="bg-blue-600 text-white py-3 px-6 rounded-lg w-full transition duration-300 ease-in-out hover:bg-blue-700" on:click={joinInterview}>
                {$t("join_now")}
            </button>
        </div>
        <div class="md:w-1/2 w-full">
            <div class="border border-gray-300 rounded-lg p-6 mb-4" style="height: 0; padding-bottom: 56.25%; position: relative;">
                <video id="preview" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; transform: scaleX(-1);" autoplay></video>
            </div>
            <select bind:value={selectedVideoDevice} on:change={updateStream} class="mb-4 border border-gray-300 py-2 px-4 rounded-lg w-full">
                {#each videoDevices as device}
                    <option value={device.deviceId}>{device.label || `Device ${device.deviceId}`}</option>
                {/each}
            </select>
            <select bind:value={selectedAudioDevice} on:change={updateStream} class="mb-4 border border-gray-300 py-2 px-4 rounded-lg w-full">
                {#each audioDevices as device}
                    <option value={device.deviceId}>{device.label || `Device ${device.deviceId}`}</option>
                {/each}
            </select>
        </div>
    </div>
</section>
