<script lang="ts">
    import { t } from 'svelte-i18n';
    import {currentContent, currentPage} from "../../redux/stores";
    import Landing from "../Landing.svelte";
    import PreInterviewContent from "../PreInterviewContent.svelte";
</script>

<div class="flex flex-col items-center justify-center h-screen bg-gray-100">
    <div class="text-center">
        <h1 class="text-4xl font-bold mb-4">{$t("error_occurred")}</h1>
        <p class="mb-6">{$t("contact_for_assistance")}</p>
        <div class="flex justify-center gap-4">
            <button
                    class="btn btn-secondary"
                    on:click={() => {
                        $currentContent = Landing;
                        $currentPage = PreInterviewContent;
                     }}
            >
                {$t("return_home_screen")}
            </button>
        </div>
    </div>
    <div class="flex items-center justify-center mt-8">
        <div class="card bg-base-100 shadow-xl">
            <div class="card-body items-center text-center">
                <div class="card-title">
                    <span class="iconify text-6xl" data-icon="mdi-alert" data-inline="false"></span>
                </div>
                <p>{$t("unexpected_error_occurred")}</p>
                <p>{$t("issue_logged")}</p>
            </div>
        </div>
    </div>
</div>
