<script lang="ts">
    import { t } from 'svelte-i18n';
    import {username} from "../../redux/stores";
    import {toHomeScreen} from "../Utils";
</script>

<div class="flex flex-col items-center justify-center h-screen bg-gray-100">
    <div class="text-center">
        <h1 class="text-4xl font-bold mb-4">{$t("interview_finished")}</h1>
        <div class="flex justify-center gap-4 mb-6">
            <button class="btn btn-secondary" on:click={()=> toHomeScreen()}>{$t("return_home_screen")}</button>
        </div>
        <button class="btn btn-link">{$t("submit_feedback")}</button>
    </div>
    <div class="flex items-center justify-center">
        <div class="card bg-base-100 shadow-xl">
            <div class="card-body items-center text-center">
                <div class="card-title">
                    <span class="iconify text-6xl" data-icon="mdi-lock" data-inline="false"></span>
                </div>
                <p>{$t("thank_you_for_joining", { username })}</p>
                <p>{$t("interview_recorded")}</p>
            </div>
        </div>
    </div>
</div>