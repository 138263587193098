import {isAudioPlaying} from "../redux/stores";

export class AdjustableTimer {
    private remainingTime: number;
    private intervalId: NodeJS.Timeout | null;

    constructor(initialTime: number) {
        this.remainingTime = initialTime;
        this.intervalId = null;
    }

    start() {
        if (this.intervalId !== null) {
            // Timer is already running
            return;
        }

        this.intervalId = setInterval(() => {
            this.remainingTime--;

            if (this.remainingTime <= 0) {
                 isAudioPlaying.set(false)
                this.stop();
            }
        }, 1000); // Decreases the remaining time every second
    }

    addTime(seconds: number) {
        this.remainingTime += seconds;
    }

    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}